import React from "react"
import PageHeader from "../../components/PageHeader/PageHeader"
import NavPages from "../../components/NavPages/NavPages"
import Gradient from "../../components/Generator/Gradient/Gradient"
import Seo from "../../components/Seo/Seo"
import Footer from "../../components/Footer/Footer"

const GradientPage = ({ location }) => {
  const language = location.pathname.substr(1, 2)
  return (
    <>
      <Seo
        title="CSS Gradient generator | CSSGENERATOR.PL"
        description="CSS GRADIENT GENERATOR | An easy to use tool that allows you to generate ready CSS rules. Adjust values and copy the finished code."
        location={location}
        language={language}
        hreflang
      />
      <PageHeader
        h1="Gradient Generator"
        desc="An easy to use tool that allows you to generate ready CSS rules. Adjust gradient values and copy the ready code."
        language={language}
      />
      <NavPages language={language} />
      <Gradient language={language} />
      <Footer language={language} />
    </>
  )
}

export default GradientPage
